import styles from "~/sass/organisms/Testimonials.module.scss";
import Testimonial from "~/components/molecules/Testimonial";

export default function Testimonials({
  heading,
  items,
  className,
  isPersonalRelocationNetwork,
}) {
  const classNames = [styles["Testimonials"], className].join(" ").trim();
  return (
    <section className={classNames}>
      <div className={styles["Testimonials__container"]}>
        {heading && (
          <h2 className={styles["Testimonials__heading"]}>{heading}</h2>
        )}
        <div className={styles["Testimonials__grid"]}>
          {items.map((item, index) => (
            <Testimonial
              {...item}
              isPersonalRelocationNetwork={isPersonalRelocationNetwork}
              key={index}
            />
          ))}
        </div>
      </div>
    </section>
  );
}
