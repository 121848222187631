import { StyleSheet, css } from "aphrodite";
import dynamic from "next/dynamic";
import testimonialStyles from "~/sass/molecules/Testimonial.module.scss";

import ReviewRatings from "../Agents/Reviews/ReviewRatings";

import { COLORS } from "~/config/themes";

const Image = dynamic(() => import("~/components/Image"), { ssr: false });

const TestimonialInfo = ({ testimonial, key }) => {
  const parseName = () => {
    const splitName = testimonial.name.split(" ");
    let initials = [];
    for (let i = 0; i < splitName.length; i++) {
      initials.push(splitName[i][0]);
    }
    return initials.join(" ");
  };
  return (
    <div className={css(styles.testimonialContainer, key)}>
      <div className={css(styles.infoRow)}>
        {testimonial.picture ? (
          <Image
            imageSrc={
              testimonial.picture
                ? testimonial.picture
                : "/static/profile_default.webp"
            }
            defaultSrc={"/static/profile_default.webp"}
            alt={`picture of ${testimonial.name}`}
            className={css(styles.picture)}
            containerClassName={css(styles.pictureContainer)}
          />
        ) : (
          <div
            className={`${testimonialStyles["Testimonial__initials-circle"]} ${
              testimonialStyles[`--${testimonial.circleColor}`]
            } ${testimonialStyles[`--old-component`]}`}
          >
            <h3>{parseName()}</h3>
          </div>
        )}
        <div className={css(styles.infoSection)}>
          <ReviewRatings readOnly={true} starSize={styles.stars} rating={5} />
          <div className={css(styles.reviewInfo)}>
            <span
              className={css(styles.name)}
            >{`${testimonial.name} from ${testimonial.location}`}</span>{" "}
            <span className={css(styles.divider)}>/</span>{" "}
            <p className={css(styles.clientType)}>{testimonial.review_type}</p>
          </div>
        </div>
      </div>
    </div>
  );
};

const styles = StyleSheet.create({
  testimonialContainer: {
    width: "100%",
  },
  pictureContainer: {
    width: 72,
    height: 72,
    borderRadius: "100%",
    marginRight: 50,
    marginRight: 20,
    position: "relative",
  },
  picture: {
    borderRadius: "100%",
  },
  review: {
    fontSize: 20,
    fontWeight: 400,
    marginBottom: 40,
  },
  stars: {
    width: 16,
    height: 16,
  },
  name: {
    fontSize: 22,
    fontWeight: 600,

    "@media only screen and (max-width: 1200px)": {
      fontSize: 18,
    },
  },
  divider: {
    color: COLORS.PRIMARY_ACTION(),
    fontSize: 20,
    fontWeight: 600,
  },
  clientType: {
    fontSize: 14,
    fontWeight: 400,
    color: COLORS.BLACK(0.5),

    "@media only screen and (max-width: 1200px)": {
      fontSize: 12,
    },
  },
  infoRow: {
    display: "flex",
  },
});

export default TestimonialInfo;
