import MainNav from "~/components/navigation/MainNav";
import Footer from "~/components/Footer";

export default function Default(props) {
  const { children } = props;

  return (
    <>
      <MainNav theme="white" background="transparent" floating sticky />
      {children}
      <Footer />
    </>
  );
}
