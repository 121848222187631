import { css, StyleSheet } from "aphrodite";
import Link from "next/link";

import Button from "~/components/Form/Button";
import ContentWrapper from "../ContentWrapper";

import { COLORS, NAV_DESKTOP_HEIGHT, NAV_MOBILE_HEIGHT } from "~/config/themes";

const Hero = ({ content, backgroundSrc, heroStyles, contentWrapperStyles }) => {
  const dynamicStyle = StyleSheet.create({
    background: {
      background: `url(${backgroundSrc})`,
      backgroundSize: "cover",
      backgroundRepeat: "no-repeat",
      backgroundPosition: "center center",
    },
  });

  return (
    <div
      className={css(
        styles.locationHeaderContainer,
        dynamicStyle.background,
        heroStyles
      )}
    >
      <div className={css(styles.overlay)}></div>
      <ContentWrapper className={contentWrapperStyles}>
        {content}
      </ContentWrapper>
    </div>
  );
};

const styles = StyleSheet.create({
  locationHeaderContainer: {
    display: "flex",
    position: "relative",
    justifyContent: "center",
    paddingTop: `calc(${NAV_DESKTOP_HEIGHT}px + 40px)`,
    paddingBottom: 88,

    "@media only screen and (max-width: 1023px)": {
      paddingTop: `calc(${NAV_MOBILE_HEIGHT}px + 30px)`,
    },
    "@media only screen and (max-width: 767px)": {
      paddingLeft: 20,
      paddingRight: 20,
    },
  },
  overlay: {
    background:
      "linear-gradient(180deg, #448ACF 0%, #4A96E0 48.5%, rgba(99, 159, 220, 0) 70.1%)",
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
  },
});
export default Hero;
