import { css, StyleSheet } from "aphrodite";

import ValuePropInfoCard from "./Common/ValuePropInfoCard";
const WhyREA = () => {
  return (
    <div className={css(styles.whyReaContainer)}>
      <ValuePropInfoCard
        icon={"/static/homepage/star-badge.svg"}
        title={"Only Top Agents"}
        description={
          "RealEstateAgents.com’s network covers the entire country and features top closers in every market."
        }
      />
      <ValuePropInfoCard
        icon={"/static/homepage/sparkle-computer.svg"}
        title={"Expert Recommendations"}
        description={
          "Our AI-powered, machine learning-backed platform leverages millions of data points to connect you with a custom real estate agent list."
        }
      />
      <ValuePropInfoCard
        icon={"/static/homepage/feed-with-heart.svg"}
        title={"Compare Real Estate Agents"}
        description={
          "Our profiles include information to help you compare agents and find the perfect local REALTOR®!"
        }
      />
    </div>
  );
};

const styles = StyleSheet.create({
  whyReaContainer: {
    gridTemplateColumns: "repeat(3, minmax(0, 1fr))",
    display: "grid",
    columnGap: 30,
    paddingTop: 15,

    "@media only screen and (max-width: 1023px)": {
      rowGap: 30,
      gridTemplateColumns: "repeat(1, minmax(0, 1fr))",
    },
  },
});
export default WhyREA;
