import styles from "~/sass/molecules/Card.module.scss";

/**
 * A basic Card component
 *
 * @component
 * @example
 * return (
 *  <Card>
 *    Hello World!
 * </Card>
 * )
 */
export default function Card({ children }) {
  return <div className={styles["Card"]}>{children}</div>;
}
