import ReviewRatings from "~/components/Agents/Reviews/ReviewRatings";
import { StyleSheet } from "aphrodite";

export default function StarRating({ rating }) {
  const inlineStyles = StyleSheet.create({
    starSize: {
      fontSize: 16,
    },
  });

  return (
    <ReviewRatings
      rating={rating}
      readOnly={true}
      starSize={inlineStyles.starSize}
    />
  );
}
