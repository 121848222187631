import { css, StyleSheet } from "aphrodite";

import ValuePropInfoCard from "./ValuePropInfoCard";
const ValuePropTwo = () => {
  return (
    <div className={css(styles.valuePropContainer)}>
      <ValuePropInfoCard
        icon={"/static/wallet.webp"}
        title={"$21,500 savings"}
        description={
          "Save an average of $21,500 buying a home using our agents."
        }
        altLayout={true}
      />
      <ValuePropInfoCard
        icon={"/static/rocket.webp"}
        title={"18 days faster"}
        description={
          "Our agents sell homes on average 18 days faster compared to the typical agent."
        }
        altLayout={true}
      />
      <ValuePropInfoCard
        icon={"/static/circle_star.webp"}
        title={"4.9 star rating"}
        description={
          "Top agents score an average rating of 4.9 out of 5, compared to typical agent of 4.4."
        }
        altLayout={true}
      />
    </div>
  );
};

const styles = StyleSheet.create({
  valuePropContainer: {
    gridTemplateColumns: "repeat(3, minmax(0, 1fr))",
    display: "grid",
    columnGap: 30,

    "@media only screen and (max-width: 1023px)": {
      rowGap: 30,
      gridTemplateColumns: "repeat(1, minmax(0, 1fr))",
    },
  },
});
export default ValuePropTwo;
