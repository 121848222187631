import { css, StyleSheet } from "aphrodite";

const CtaSection = ({ action, title, description, noHeader }) => {
  return (
    <div className={css(styles.ctaContainer)}>
      {noHeader ? (
        <div className={css(styles.title)}>{title}</div>
      ) : (
        <h2 className={css(styles.title)}>{title}</h2>
      )}
      <p className={css(styles.description)}>{description}</p>
      {action}
    </div>
  );
};

const styles = StyleSheet.create({
  title: {
    fontWeight: 600,
    fontSize: 42,
    textAlign: "center",
    marginBottom: 20,

    "@media only screen and (max-width: 767px)": {
      fontSize: 30,
      lineHeight: "35px",
    },
  },
  description: {
    fontWeight: 400,
    fontSize: 18,
    textAlign: "center",
    marginBottom: 40,

    "@media only screen and (max-width: 767px)": {
      fontSize: 16,
    },
  },
  ctaContainer: {
    width: "100%",
    padding: "100px 50px",
    background: "rgba(243, 243, 239, 1)",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",

    "@media only screen and (max-width: 767px)": {
      padding: "50px 30px",
    },
  },
});
export default CtaSection;
