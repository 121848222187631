import { css, StyleSheet } from "aphrodite";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { COLORS } from "../../config/themes";
import TestimonialInfo from "./TestimonialInfo";
import InputComponentCTA from "./InputComponentCTA";

import ImageComp from "../Image";
const ValuePropCTA = ({
  title,
  subtext,
  bulletPoints,
  image,
  type,
  testimonial,
  placeholder,
  searchOptions,
  altText,
}) => {
  const renderBullets = () => {
    return bulletPoints.map((bullet, index) => {
      return (
        <div className={css(styles.bullet)} key={`cta_bullet_${index}`}>
          <FontAwesomeIcon
            icon={["fas", "check-circle"]}
            className={css(styles.checkIcon)}
          />
          <span className={css(styles.bulletValue)}>{bullet}</span>
        </div>
      );
    });
  };

  return (
    <div
      className={css(styles.ctaContainer, type === "buy" && styles.reverseFlex)}
    >
      <ImageComp
        alt={altText || ""}
        imageSrc={image}
        containerClassName={css(styles.image)}
      />
      <div className={css(styles.contentContainer)}>
        <p className={css(styles.subtext)}>{subtext}</p>
        <h2 className={css(styles.title)}>{title}</h2>
        {renderBullets()}
        <div className={css(styles.actionContainer)}>
          <InputComponentCTA
            inputPlaceholder={placeholder}
            type={type}
            options={searchOptions}
            inputGroupStyle={[styles.inputGroup]}
            inputContainerStyle={[styles.inputContainer]}
          />
        </div>
        <div className={css(styles.divider)} />
        <div className={css(styles.tesimonial)}>
          <p className={css(styles.review)}>"{testimonial.review}"</p>
          <TestimonialInfo testimonial={testimonial} />
        </div>
      </div>
    </div>
  );
};

const styles = StyleSheet.create({
  ctaContainer: {
    display: "flex",
    justifyContent: "space-between",

    "@media only screen and (max-width: 1023px)": {
      flexDirection: "column",
      justifyContent: "flex-start",
      alignItems: "center",
    },
  },
  subtext: {
    fontSize: 16,
    fontWeight: 500,
    lineHeight: "19.36px",
    letterSpacing: "3%",
    marginBottom: 30,
    textTransform: "uppercase",
    color: COLORS.BLACK(0.7),

    "@media only screen and (max-width: 767px)": {
      fontSize: 14,
      fontWeight: 500,
      letterSpacing: "1%",
      lineHeight: "18px",
      marginBottom: 16,
    },
  },
  title: {
    fontWeight: 600,
    fontSize: 42,
    lineHeight: "54px",
    marginBottom: 30,

    "@media only screen and (max-width: 767px)": {
      fontSize: 28,
      fontWeight: 600,
      lineHeight: "34px",
      marginBottom: 20,
    },
  },
  image: {
    width: 625,
    height: 625,

    "@media only screen and (max-width: 1439px)": {
      width: 500,
      height: 500,
    },

    "@media only screen and (max-width: 1023px)": {
      width: "100%",
      height: 625,
      marginBottom: 60,
    },

    "@media only screen and (max-width: 767px)": {
      height: 400,
    },

    "@media only screen and (max-width: 424px)": {
      height: 300,
    },
  },
  contentContainer: {
    width: "40%",

    "@media only screen and (max-width: 1023px)": {
      width: "100%",
    },
  },
  bullet: {
    marginBottom: 18,
    display: "flex",
  },
  checkIcon: {
    marginRight: 10,
    color: "rgba(0, 107, 184, 1)",
    marginTop: 5,
    background: "transparent",
  },
  bulletValue: {
    fontSize: 18,
    weight: 400,
    lineHeight: "28px",
  },
  actionContainer: {
    marginTop: 40,
    "@media only screen and (max-width: 767px)": {
      width: "100%",
    },
  },
  inputContainer: {
    height: 60,
    padding: 0,
    width: "100%",
    borderRadius: 5,

    "@media only screen and (max-width: 1023px)": {
      width: "100%",
    },
  },
  inputGroup: {
    background: "rgba(248, 248, 248, 1)",
    height: "100%",
    width: "100%",
    borderTopLeftRadius: 5,
    borderBottomLeftRadius: 5,

    "@media only screen and (max-width: 1300px)": {
      width: "100%",
      borderRadius: 5,
    },
  },
  input: {
    borderTopLeftRadius: 5,
    borderBottomLeftRadius: 5,
    fontSize: 14,
  },
  button: {
    width: 245,
    fontSize: 16,
    fontWeight: 700,
    background: COLORS.PRIMARY_ACTION(),
    borderTopRightRadius: 5,
    borderBottomRightRadius: 5,

    "@media only screen and (max-width: 1023px)": {
      width: 330,
    },

    "@media only screen and (max-width: 1199px)": {
      display: "none",
    },
  },
  mobileButton: {
    display: "none",

    "@media only screen and (max-width: 1199px)": {
      display: "inline-block",
      marginTop: 10,
      width: "100%",
      borderRadius: 5,
    },
  },
  icon: {
    marginRight: 12,
    fontSize: 16,
    opacity: 0.6,
  },
  reverseFlex: {
    flexDirection: "row-reverse",
  },
  review: {
    fontSize: 16,
    fontWeight: 400,
    lineHeight: "24px",
    opacity: 0.8,
    marginBottom: 20,
  },
  divider: {
    width: "100%",
    borderTop: "1px solid rgba(39, 39, 39, 0.15)",
    marginTop: 40,
    marginBottom: 30,
  },
});
export default ValuePropCTA;
