import { css, StyleSheet } from "aphrodite";
import ImageComp from "../Image";
const ValuePropInfoCard = ({
  icon,
  isIconImage = true,
  title,
  description,
  altLayout,
  altText,
  key,
  cardStyles,
}) => {
  return (
    <div
      className={css(
        styles.valuePropInfoCard,
        cardStyles,
        altLayout && styles.altLayout
      )}
      key={key}
    >
      {altLayout ? (
        <>
          <div className={css(styles.headerRow)}>
            {isIconImage && (
              <ImageComp
                imageSrc={icon}
                containerClassName={css(
                  styles.iconContainer,
                  styles.altIconContainer
                )}
                className={css(styles.icon)}
                alt={altText}
              />
            )}
            <p className={css(styles.title, styles.altTitle)}>{title}</p>
          </div>
          <div className={css(styles.content)}>
            <p className={css(styles.description)}>{description}</p>
          </div>
        </>
      ) : (
        <>
          {isIconImage && (
            <ImageComp
              containerClassName={css(styles.iconContainer)}
              className={css(styles.icon)}
              imageSrc={icon}
              alt={altText}
            />
          )}
          <div className={css(styles.content)}>
            <p className={css(styles.title)}>
              {!isIconImage ? (
                <span>
                  {icon} {title}
                </span>
              ) : (
                <>{title}</>
              )}
            </p>
            <p className={css(styles.description)}>{description}</p>
          </div>
        </>
      )}
    </div>
  );
};

const styles = StyleSheet.create({
  valuePropInfoCard: {
    gridTemplateColumns: "49px auto",
    display: "grid",
    columnGap: 19,

    border: "1px solid rgba(233, 233, 233, 1)",
    boxShadow: "0px 0px 28px 0px rgba(30, 41, 59, 0.08)",
    borderRadius: 5,
    padding: "30px 35px",
    height: "100%",

    "@media only screen and (max-width: 1279px)": {
      padding: "20px 25px",
      columnGap: 15,
      gridTemplateColumns: "30px auto",
    },

    "@media only screen and (max-width: 1023px)": {
      padding: "30px 35px",
      columnGap: 19,
      gridTemplateColumns: "49px auto",
    },

    "@media only screen and (max-width: 767px)": {
      padding: "20px 25px",
      columnGap: 15,
      gridTemplateColumns: "30px auto",
    },
  },
  title: {
    fontSize: 20,
    fontWeight: 600,
    marginBottom: 16,
  },
  content: {
    display: "flex",
    flexDirection: "column",
  },
  description: {
    fontSize: 16,
    fontWeight: 400,
    opacity: 0.8,
  },
  iconContainer: {
    width: 49,
    height: 49,
    position: "relative",
    overflow: "hidden",

    "@media only screen and (max-width: 1279px)": {
      width: 30,
      height: 30,
    },

    "@media only screen and (max-width: 1023px)": {
      width: 49,
      height: 49,
    },
    "@media only screen and (max-width: 767px)": {
      width: 30,
      height: 30,
    },
  },
  headerRow: {
    display: "flex",
    alignItems: "center",
    height: 50,
    marginBottom: 16,
  },
  altLayout: {
    display: "flex",
    flexDirection: "column",
  },
  altTitle: {
    margin: 0,
  },
  altIconContainer: {
    marginRight: 19,

    "@media only screen and (max-width: 1279px)": {
      marginRight: 15,
    },

    "@media only screen and (max-width: 1023px)": {
      marginRight: 19,
    },

    "@media only screen and (max-width: 767px)": {
      marginRight: 15,
    },
  },
});
export default ValuePropInfoCard;
